.b-breadcrumbs {
  padding-top: 20px;
  padding-bottom: 20px;

  a {
    display: inline-block;
    vertical-align: top;
    color: #4b4b4c;
    font-size: 14px;
    position: relative;
    padding-right: 23px;

    &:after {
      content: '';
      width: 7px;
      height: 7px;
      display: block;
      position: absolute;
      right: 7px;
      top: 8px;
      background: url('../img/sprite/main-page.png') -511px -41px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    display: inline-block;
    vertical-align: top;
    color: #a6a6a6;
    font-size: 14px;
  }
}
.b-main-catalog {
  margin-top: 30px;

  .b-catalog-tabs {
    padding: 0;
    margin: 0;
    list-style: none;
    border-bottom: 1px solid #005687;

    li {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;

      &.active {
        a {
          font-family: $bold;
          background: #005687;
          color: #fff;
        }
      }

      a {
        color: #000000;
        font-size: 18px;
        display: block;
        background: #ededed;
        padding: 10px 20px;
      }
    }
  }

  .b-catalog-list {
    margin-top: 0;
    padding-bottom: 0;

    &.active {
      margin-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.b-catalog-list {
  margin-top: 30px;
  padding-bottom: 30px;

  .catalog-slider {
    margin: 0 -10px;

    .catalog-slider-item {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .slick-dots {
    list-style: none;
    padding: 0;
    margin: 30px 0 0;
    text-align: center;

    li {
      display: inline-block;
      vertical-align: top;
      padding: 0;
      margin: 0 5px 0 0;
      padding: 3px;

      &.slick-active {
        padding: 2px;
        border: 1px solid #005687;
        border-radius: 50%;
      }
    }

    button {
      border: none;
      width: 11px;
      height: 11px;
      background: #005687;
      border-radius: 50%;
      font-size: 0;
      display: block;
      padding: 0;
    }
  }

  .b-catalog-item {
    border: 1px solid #b8b8b8;
    padding: 10px 10px 20px;
    position: relative;

    &.horizontally {
      .title {
        margin-bottom: 20px;
      }

      .b-code {
        float: right;
        margin-top: -30px;
      }

      .price {
        span {
          margin-top: 15px;
        }
      }

      .center {
        position: relative;

        .links {
          bottom: -10px;
          top: auto;
          left: 100px;
        }
      }

      .save-money {
        margin-top: 15px;
      }

      .buy-btn {
        margin: 15px 0 0;
      }
    }

    &.simple {
      margin-bottom: -1px;

      .b-catalog-item-preview {
        a {
          img {
            max-width: 58% !important;
          }
        }
      }

      .b-code {
        margin-top: 15px;
        display: block;
      }

      .price {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
      }

      .links {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: 0;
        margin-right: 30px;
      }

      .buy-btn {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        margin: 0;
      }

      .b-catalog-item-body {
        display: inline-block;
        margin: 0;
        padding: 0;
        vertical-align: middle;
      }
    }

    .b-code {
      color: #626262;
      font-size: 14px;
    }

    .b-catalog-item-head {
      .b-discount {
        width: 61px;
        height: 75px;
        background: url('../img/sprite/main-page.png') -374px -133px;
        position: absolute;
        right: 10px;
        top: -1px;
        color: #ffffff;
        font-size: 14px;
        text-align: center;
        padding-top: 5px;

        span {
          font-size: 24px;
          font-family: $bold;
          line-height: 20px;
        }
      }

      .b-catalog-item-preview {
        margin-top: 5px;

        a {
          display: block;
          text-align: center;

          img {
            max-width: 100%;
            margin: 0 auto;
          }

          &:hover {
            .title {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .title {
      color: #005687;
      font-size: 18px;
      font-family: $bold;
      display: block;
      line-height: 16px;
      padding: 10px 5px 0;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }

    .b-catalog-item-body {
      position: relative;
      padding-top: 10px;

    }

    .price {
      color: #fd0000;
      font-size: 24px;
      font-family: $bold;
      display: block;
      text-align: center;
      line-height: 27px;
      clear: both;
      position: relative;

      span {
        color: #a6a6a6;
        font-size: 14px;
        text-decoration: line-through;
        display: block;
        line-height: 12px;
      }
    }

    .links {
      position: absolute;
      right: 0;
      top: 17px;

      .bg-compare_card_icon {
        &:hover {
          background: url('../img/sprite/main-page.png') -469px -410px;
        }
      }

      .bg-favourites_card_icon {
        margin-left: 10px;

        &:hover {
          background: url('../img/sprite/main-page.png') -469px -299px;
        }
      }
    }

    .save-money {
        display: block;
        text-align: center;
        color: #000000;
        font-size: 18px;

        span {
          color: #fd0000;
          font-family: $bold;
        }
      }


    .b-description-list {
      padding-top: 10px;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          color: #626262;
          font-size: 14px;
          display: block;
          line-height: 17px;

          span {
            color: #005687;
            font-family: $bold;
          }
        }
      }
    }

    .buy-btn {
      max-width: 185px;
      height: 35px;
      display: block;
      margin: 35px auto 0;
      background: #005687;
      border-radius: 0;
      color: #ffffff;
      font-size: 18px;
      line-height: 30px;
      padding: 0;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);

      .custom-icon {
        margin-top: 8px;
        margin-right: 5px;
      }

      &:hover {
        background: #013b5c;
        box-shadow: 0 0 0 0;
      }
    }
  }
}
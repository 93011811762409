*{
  font-family: $reg;
}

*:hover,
*:active,
*:focus {
  outline: none;
}

.container {
  max-width: 1310px;
}

a:hover {
  text-decoration: none;
}

.custom-icon {
  display: inline-block;
  vertical-align: top;
}

.col-custom {
  position: initial;
}

.see-all-link {
  color: #005687;
  font-size: 18px;
  display: block;
  float: right;
  text-decoration: underline;

  .custom-icon {
    margin: 7px 0 0 10px;
  }

  &:hover {
    color: #013b5c;

    .custom-icon {
      background: url('../img/sprite/main-page.png') -335px -356px;
    }
  }
}

.form-checkbox {
  input[type="checkbox"] {
    display:none;
  }
  input[type="checkbox"] + label {
    padding-left: 25px;
  }
  input[type="checkbox"] + label span {
    display:inline-block;
    width: 15px;
    height: 15px;
    margin:-1px 4px 0 -25px;
    border: 1px solid #898989;
    vertical-align:middle;
    position: relative;
    cursor:pointer;
  }
  input[type="checkbox"]:checked + label span{
    margin-left: -25px;
  }
  input[type="checkbox"]:checked + label span:before{
    content: '';
    position: absolute;
    left: 0px;
    width: 8px;
    height: 2px;
    top: 7px;
    background: #005687;
    transform: rotate(50deg);
  }
  input[type="checkbox"]:checked + label span:after{
    content: '';
    position: absolute;
    left: 4px;
    width: 10px;
    top: 6px;
    height: 2px;
    background: #005687;
    transform: rotate(-50deg);
  }
}

.tabs {
  .tabs__content {
    overflow: hidden;
    height: 0;
    width: 100%;

    &.active {
      height: 100%;
    }
  }
}

.slick-track {
  margin: 0;
}

.slick-dots button {
  cursor: pointer;
}
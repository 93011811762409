@media screen and (min-width: 992px) {
  .col-md-5th {
    max-width: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 1230px) {
  .b-main-menu {
    .b-burger-menu {
      display: block;
      color: #fff !important;
      line-height: 17px;
      margin-top: 9px;
      cursor: pointer;

      i {
        margin-right: 5px;
      }
    }

    .b-first-menu {
      margin-left: 20px;
      display: none;

      .custom-icon {
        display: none;
      }

      & > li {
        a {
          padding: 0 10px;
        }
      }


      li:first-child {
        a {
          padding-left: 20px;
        }
      }

      & > li:hover {
        a {
          background: none;
          color: #fff;
        }
      }

      .b-sub-menu {
        position: relative;
        top: 0;
        left: 0;
        background: none;
        box-shadow: none;

        .b-second-menu {
          background: none;

          & > li {
            &:hover {
              background: none;
            }
            & > a {
              color: #fff;

              &:hover {
                background: none;
              }
            }

            .b-third-menu-container {
              position: relative;
              left: 20px;
              top: 0;
              background: none;

              ul > li a {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .b-catalog-page .b-sort label {
    margin-right: 20px;
  }

  .b-catalog-page .b-sort select {
    margin-left: 0;
  }

  .b-card-section .b-card-info .b-links a {
    margin-right: 15px;
  }

  .b-description-table .tabs__caption li {
    margin-left: 0;
  }

  .b-description-table .tabs__caption li a {
    font-size: 14px;
    padding: 5px 10px;
  }

  .b-description-table .item table thead td,
  .b-description-table .item table tbody tr td{
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  .b-header-logo {
    width: 100%;
    display: block;
  }

  .b-main-menu {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
  }

  .b-header-nav-links {
    text-align: right;
    float: right;
    margin-top: 17px;
    background: #005687;
    padding: 2px 0px 2px 15px;
    margin-right: -15px;

    a {
      font-size: 0;
      margin: 0;
      padding: 0 5px;
    }

    .bg-compare_icon {
      margin-top: 5px;
      background: url('../img/sprite/main-page.png') -469px -136px;
    }

    .bg-favourites_icon {
      margin-top: 3px;
      background: url('../img/sprite/main-page.png') -324px -406px;
    }

    .bg-cart_icon {
      margin-top: 4px;
      background: url('../img/sprite/main-page.png') -411px -406px;
    }
  }

  .b-header-search {
    margin: 10px 149px -52px 100px;

    input {
      height: 34px;
      border: none;
      line-height: 34px;
    }

    .btn-submit {
      height: 36px;
      right: -1px;
      top: -1px;
    }
  }

  .b-main-catalog .b-catalog-tabs {
    border: none;
  }

  .b-main-catalog .b-catalog-tabs li {
    margin-left: 5px;
    margin-bottom: 5px;

    a {
      font-size: 13px
    }
  }

  .b-top-nav {
    display: none;
  }

  .b-seo-links .bg-grey_logo .bg-guarantee_icon {
    left: 0;
  }

  .b-footer ul {
    margin-bottom: 15px;
  }

  .b-card-section .b-card-info .b-preview-list ul {
    width: 100%;
    display: block;

    li {
      width: 23%;
      display: inline-block;
      vertical-align: top;

      img {
        width: 100%;
      }
    }
  }

  .b-card-section .b-card-info .b-preview-list .preview-picture {
    width: 100%;
    margin-left: 0;

    img {
      margin: 0 auto;
      max-width: 100%;
    }
  }

  .b-similar-items .tabs__caption li {
    margin-left: 10px;
  }

  .b-similar-items .tabs__caption li a {
    font-size: 14px;
  }

}

@media screen and (max-width: 768px) {
  .main-right-slider {
    display: none;
  }

  .b-main-catalog .b-catalog-tabs li {
    margin-left: 0;
  }

  .b-seo-links span {
    margin-bottom: 20px;
  }

  .b-header-contacts-panel {
    padding-bottom: 10px;

    .b-header-adress {
      float: none;
      display: block;
      line-height: 18px;

      .custom-icon {
        margin-top: 0;
      }
    }
  }

  .b-main-menu .b-first-menu {
    padding-top: 40px;
  }

  .b-sidebar {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 20px;
  }

  .b-catalog-page .b-catalog-container {
    width: 100%;
    margin-left: 0;

    .b-sort {
      text-align: center;
      margin: 0 auto 15px;
    }
  }

  .b-catalog-page .b-catalog-head .pagination {
    text-align: center;
  }

  .b-card-section .b-card-info .item-right .code {
    padding: 15px 0 15px;
  }

  .b-card-section .b-card-info .item-right {
    float: none;
    display: block;
    text-align: left;
  }

  .b-description-table .tabs__caption li {
    margin-bottom: 5px;
    margin-left: 0;
  }

  .b-card-section .b-card-info .item-right a {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
  }

  .b-card-section .b-card-info .item-left {
    display: block;
    text-align: center;
  }

  .b-card-section .b-card-info .item-left .btn {
    margin: 10px auto 0;
  }

  .b-card-section .b-card-info .item-right {
    text-align: center;
  }

  .b-card-section .b-card-info .b-links {
    text-align: center;
  }

  .b-catalog-head .tabs-links {
    display: none;
  }

  .b-header-search {
    margin: 10px 82px -52px 87px;
  }
}

@media screen and (max-width: 600px) {
  .b-seo-article {
    margin-bottom: 20px;
  }
  .b-review-list h3 {
    font-size: 20px;
  }

  .b-header-logo {
    img {
      max-width: 100%;
    }
  }

  .see-all-link {
    float: none;
    display: block;
  }

  .call-back-banner span {
    font-size: 25px;
    line-height: 28px;
  }

  .call-back-banner p {
    font-size: 25px;
    line-height: 28px;
  }

  .b-header-search {

  }

  .b-description-table .item table thead td {
    padding: 10px;
  }

  .b-description-table .item table tbody tr td {
    padding: 10px;
  }
  .b-similar-items .tabs__caption li {
    width: 100%;
    display: block;
    margin-left: 0;
  }

  .b-similar-items .item .b-catalog-list {
    margin: 30px 0 0;
  }

  .b-catalog-list .b-catalog-item .b-description-list {
    text-align: center;
  }
}
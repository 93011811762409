.b-banner-section {
  margin-top: 30px;

  img {
    width: 100%;
  }
}

.main-slider {
  .slick-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 20px;
    left: 20px;
    height: 11px;

    li {
      display: inline-block;
      vertical-align: top;
      padding: 0;
      margin: 0 5px 0 0;
      padding: 3px;

      &.slick-active {
        padding: 2px;
        border: 1px solid #fff;
        border-radius: 50%;
      }
    }

    button {
      border: none;
      width: 11px;
      height: 11px;
      background: #fff;
      border-radius: 50%;
      font-size: 0;
      display: block;
      padding: 0;
    }
  }
}

.b-sidebar {
  width: 225px;
  flex: 0 0 225px;

  .tabs__caption {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;

    li {
      width: 50%;
      float: left;

      &:first-child {
        border-left: 1px solid #b8b8b8;
        border-top: 1px solid #b8b8b8;
        background: #fff;

        &.active {
          border-left: none;
          border-top: none;
        }
      }

      &:last-child {
        border-right: 1px solid #b8b8b8;
        border-top: 1px solid #b8b8b8;
        background: #fff;

        &.active {
          border-right: none;
          border-bottom: none;
        }
      }

      a {
        color: #666666;
        font-size: 18px;
        display: block;
        text-align: center;
        padding: 3px 0;
      }

      &.active {
        a {
          color: #ffffff;
          font-size: 18px;
          font-family: $bold;
          background: #005687;
        }
      }
    }
  }

  .item {
    border: 1px solid #b8b8b8;
    margin-top: -1px;
    display: none;

    &.active {
      display: block;
    }

    .b-catalog-menu {
      list-style: none;
      padding: 20px;
      margin: 0;

      li {
        padding-bottom: 5px;

        a {
          color: #656565;
          font-size: 16px;
          display: block;
          line-height: 20px;

          &:hover {
            color: #005687;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .b-filter {
    padding: 25px 15px 30px 25px;

    label {
      color: #005687;
      font-size: 16px;
      line-height: 18px;
      display: block;
      margin-bottom: 15px;
    }

    .form-group {
      margin-bottom: 25px;
    }

    .checkbox {
      margin-bottom: 10px;

      label {
        color: #626262;
        font-size: 14px;
        margin: 0;
        cursor: pointer;
      }
      input[type="checkbox"] {
        display:none;
      }
      input[type="checkbox"] + label span {
        display:inline-block;
        width:14px;
        height:14px;
        margin:-2px 4px 0 0;
        vertical-align:middle;
        cursor:pointer;
        border: 1px solid #b8b8b8;
      }
      input[type="checkbox"]:checked + label span {
        &:after {
          content: '';
          width: 10px;
          height: 10px;
          background: #669ab7;
          display: block;
          margin: 1px 0 0 1px;
        }
      }
    }

    .refresh-btn {
      color: #005687;
      font-size: 14px;
      display: block;

      .custom-icon {
        margin-top: 6px;
        margin-right: 5px;
      }
    }
  }
}
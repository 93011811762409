.b-review-list {
  padding-top: 50px;
  padding-bottom: 55px;

  h3 {
    color: #005687;
    font-size: 25px;
    display: inline-block;

    .custom-icon {
      margin-right: 10px;
    }
  }

  .review-slider {
    margin: 0 -15px;

    .item {
      margin-right: 30px;
      margin-left: 30px;
    }
  }

  .preview {
    height: 200px;
    width: 100%;

    .text-center {
      width: 100%;
    }

    img {
      display: inline-block;
    }
  }

  span {
    text-align: center;
    color: #005687;
    font-size: 20px;
    display: block;
    font-family: $bold;
    text-decoration: underline;
  }

  p {
    color: #4b4b4c;
    font-size: 16px;
    text-align: justify;
    line-height: 20px;
    padding-top: 5px;
  }
}
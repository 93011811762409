.b-description-table {
  margin-top: 30px;

  .tabs__caption {
    padding: 0;
    margin: 0;
    list-style: none;
    border-bottom: 1px solid #005687;

    li {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;

      &.active {
        a {
          font-family: $bold;
          background: #005687;
          color: #fff;
        }
      }

      a {
        color: #000000;
        font-size: 18px;
        display: block;
        background: #ededed;
        padding: 10px 20px;
      }
    }
  }

  .item {
    padding: 20px;
    border-left: 1px solid #005687;
    border-right: 1px solid #005687;
    border-bottom: 1px solid #005687;

    table {
      width: 100%;

      thead {
        td {
          background: #005687;
          color: #ffffff;
          font-size: 18px;
          padding: 10px 40px;
        }
      }

      tbody {
        tr {
          &:nth-child(2n-2) {
            background: #f8f8fa;
          }

          td {
            color: #000000;
            font-size: 18px;
            padding: 10px 40px;
          }
        }
      }
    }
  }
}
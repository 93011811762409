.b-seo-links{
  padding-top: 60px;

  a {
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }

  .bg-grey_logo {
    position: relative;
    margin: 0 auto;

    .bg-guarantee_icon {
      position: absolute;
      left: -23px;
      top: 78px;
    }

    .bg-requirements_icon {
      position: absolute;
      left: -20px;
      top: 68px;
    }

    .bg-location_icon {
      position: absolute;
      left: -20px;
      top: 70px;
    }

    .bg-choice {
      position: absolute;
      left: -15px;
      top: 60px;
    }
  }

  span {
    color: #005687;
    font-size: 25px;
    display: block;
    text-align: center;
    padding-top: 55px;
    line-height: 28px;
  }
}


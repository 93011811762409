.bg-banner_phone_icon {
  width: 27px; height: 27px;
  background: url('../img/sprite/main-page.png') -59px -356px;
}

.bg-breadcrumb_arrow {
  width: 7px; height: 7px;
  background: url('../img/sprite/main-page.png') -511px -41px;
}

.bg-burger_icon {
  width: 24px; height: 20px;
  background: url('../img/sprite/main-page.png') -10px -454px;
}

.bg-burger_icon_hover {
  width: 24px; height: 20px;
  background: url('../img/sprite/main-page.png') -54px -454px;
}

.bg-cart_card_icon {
  width: 17px; height: 17px;
  background: url('../img/sprite/main-page.png') -469px -373px;
}

.bg-cart_icon {
  width: 23px; height: 23px;
  background: url('../img/sprite/main-page.png') -368px -406px;
}

.bg-cart_icon_hover {
  width: 23px; height: 23px;
  background: url('../img/sprite/main-page.png') -411px -406px;
}

.bg-choice {
  width: 75px; height: 103px;
  background: url('../img/sprite/main-page.png') -374px -10px;
}

.bg-delivery_icon {
  width: 25px; height: 27px;
  background: url('../img/sprite/main-page.png') -102px -406px;
}

.bg-delivery_icon_card {
  width: 29px; height: 19px;
  background: url('../img/sprite/main-page.png') -198px -356px;
}

.bg-delivery_icon_card_hover {
  width: 29px; height: 19px;
  background: url('../img/sprite/main-page.png') -247px -356px;
}

.bg-delivery_icon_hover {
  width: 25px; height: 27px;
  background: url('../img/sprite/main-page.png') -147px -406px;
}

.bg-discount_card_icon {
  width: 26px; height: 28px;
  background: url('../img/sprite/main-page.png') -10px -406px;
}

.bg-discount_card_icon_hover {
  width: 26px; height: 28px;
  background: url('../img/sprite/main-page.png') -56px -406px;
}

.bg-discount_icon {
  width: 61px; height: 75px;
  background: url('../img/sprite/main-page.png') -374px -133px;
}

.bg-favourites_card_hover_icon {
  width: 18px; height: 17px;
  background: url('../img/sprite/main-page.png') -469px -299px;
}

.bg-favourites_card_icon {
  width: 18px; height: 17px;
  background: url('../img/sprite/main-page.png') -469px -336px;
}

.bg-favourites_icon {
  width: 24px; height: 23px;
  background: url('../img/sprite/main-page.png') -192px -406px;
}

.bg-favourites_icon_grey {
  width: 24px; height: 23px;
  background: url('../img/sprite/main-page.png') -236px -406px;
}

.bg-favourites_icon_grey_hover {
  width: 24px; height: 23px;
  background: url('../img/sprite/main-page.png') -280px -406px;
}

.bg-favourites_icon_hover {
  width: 24px; height: 23px;
  background: url('../img/sprite/main-page.png') -324px -406px;
}

.bg-footer_email_icon {
  width: 15px; height: 10px;
  background: url('../img/sprite/main-page.png') -98px -454px;
}

.bg-footer_map_icon {
  width: 15px; height: 14px;
  background: url('../img/sprite/main-page.png') -374px -356px;
}

.bg-footer_phone_icon {
  width: 15px; height: 14px;
  background: url('../img/sprite/main-page.png') -409px -356px;
}

.bg-grey_logo {
  width: 157px; height: 121px;
  background: url('../img/sprite/main-page.png') -10px -98px;
}

.bg-guarantee_icon {
  width: 113px; height: 85px;
  background: url('../img/sprite/main-page.png') -132px -239px;
}

.bg-location_icon {
  width: 114px; height: 95px;
  background: url('../img/sprite/main-page.png') -187px -98px;
}

.bg-logo {
  width: 344px; height: 68px;
  background: url('../img/sprite/main-page.png') -10px -10px;
}

.bg-map_icon {
  width: 22px; height: 20px;
  background: url('../img/sprite/main-page.png') -469px -219px;
}

.bg-news_icon {
  width: 29px; height: 30px;
  background: url('../img/sprite/main-page.png') -10px -356px;
}

.bg-phone_icon {
  width: 20px; height: 20px;
  background: url('../img/sprite/main-page.png') -469px -259px;
}

.bg-reload_icon {
  width: 13px; height: 11px;
  background: url('../img/sprite/main-page.png') -511px -10px;
}

.bg-requirements_icon {
  width: 102px; height: 97px;
  background: url('../img/sprite/main-page.png') -10px -239px;
}

.bg-review_icon {
  width: 32px; height: 30px;
  background: url('../img/sprite/main-page.png') -321px -98px;
}

.bg-search_icon {
  width: 21px; height: 21px;
  background: url('../img/sprite/main-page.png') -469px -178px;
}

.bg-sert_icon {
  width: 26px; height: 26px;
  background: url('../img/sprite/main-page.png') -106px -356px;
}

.bg-sert_icon_hover {
  width: 26px; height: 26px;
  background: url('../img/sprite/main-page.png') -152px -356px;
}

.bg-slider_arrow {
  width: 24px; height: 42px;
  background: url('../img/sprite/main-page.png') -374px -278px;
}

.bg-slider_arrow_hover {
  width: 24px; height: 42px;
  background: url('../img/sprite/main-page.png') -418px -278px;
}

.bg-sub_catalog_arr {
  width: 20px; height: 6px;
  background: url('../img/sprite/main-page.png') -187px -213px;
}

.bg-tabs_item_1 {
  width: 30px; height: 30px;
  background: url('../img/sprite/main-page.png') -321px -148px;
}

.bg-tabs_item_2 {
  width: 30px; height: 30px;
  background: url('../img/sprite/main-page.png') -265px -239px;
}

.bg-tabs_item_2_hover {
  width: 30px; height: 30px;
  background: url('../img/sprite/main-page.png') -315px -239px;
}

.bg-tabs_item_3 {
  width: 30px; height: 30px;
  background: url('../img/sprite/main-page.png') -265px -289px;
}

.bg-tabs_item_3_hover {
  width: 30px; height: 30px;
  background: url('../img/sprite/main-page.png') -315px -289px;
}

.bg-compare_card_hover_icon {
  width: 17px; height: 17px;
  background: url('../img/sprite/main-page.png') -469px -410px;
}

.bg-compare_card_icon {
  width: 17px; height: 17px;
  background: url('../img/sprite/main-page.png') -432px -228px;
}

.bg-compare_icon {
  width: 22px; height: 22px;
  background: url('../img/sprite/main-page.png') -469px -10px;
}

.bg-compare_icon_grey {
  width: 22px; height: 22px;
  background: url('../img/sprite/main-page.png') -469px -52px;
}

.bg-compare_icon_grey_hover {
  width: 22px; height: 22px;
  background: url('../img/sprite/main-page.png') -469px -94px;
}

.bg-compare_icon_hover {
  width: 22px; height: 22px;
  background: url('../img/sprite/main-page.png') -469px -136px;
}

.bg-about_logo {
  width: 38px; height: 30px;
  background: url('../img/sprite/main-page.png') -374px -228px;
}

.bg-arrow {
  width: 19px; height: 14px;
  background: url('../img/sprite/main-page.png') -296px -356px;
}

.bg-arrow_hover {
  width: 19px; height: 14px;
  background: url('../img/sprite/main-page.png') -335px -356px;
}
.call-back-banner {
  padding: 45px 0 50px;
  background: url("../img/general/banner-bg.jpg") no-repeat center center / cover;
  text-align: center;

  span {
    color: #005687;
    font-size: 30px;
    font-family: $bold;
  }

  p {
    color: #005687;
    font-size: 30px;
    padding-top: 10px;

    .custom-icon {
      margin: 8px 10px 0;
    }
  }

  .call-btn {
    max-width: 185px;
    height: 35px;
    display: block;
    margin: 20px auto 0;
    background: #005687;
    border-radius: 0;
    color: #ffffff;
    font-size: 18px;
    line-height: 30px;
    padding: 0;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);

    &:hover {
      background: #013b5c;
      box-shadow: 0 0 0 0;
    }
  }
}
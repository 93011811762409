.call-modal {
  padding: 30px 80px;

  h1 {
    color: #005687;
    font-size: 30px;
    font-family: $bold;
    display: block;
    text-align: center;
  }

  span {
    color: #474747;
    font-size: 18px;
    display: block;
    line-height: 24px;
    display: block;
    text-align: center;
  }

  input[type="text"] {
    height: 35px;
    padding-left: 10px;
    width: 100%;
    border: 1px solid #898989;
    border-radius: 0;
    box-shadow: none;
    margin-top: 20px;
  }

  .call-btn {
    height: 35px;
    display: block;
    margin: 20px auto 0;
    background: #005687;
    border-radius: 0;
    color: #ffffff;
    font-size: 18px;
    line-height: 30px;
    padding: 0;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  }

  .form-checkbox {
    padding-top: 20px;

    label {
      color: #8a8a8a;
      font-size: 14px;
      display: block;
      line-height: 16px;
    }
  }
}
.b-header {
  .b-top-nav {
    min-height: 40px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        vertical-align: top;
        padding-right: 3%;

        a {
          color: #000000;
          font-size: 16px;
          line-height: 40px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-contacts-panel {
    min-height: 45px;
    background: #ededed;
  }

  &-phone {
    color: #005687;
    font-size: 18px;
    line-height: 45px;

    .custom-icon {
      margin-top: 13px;
      margin-right: 5px;
    }

    &:hover {
      text-decoration: underline;
      color: #005687;
    }
  }

  &-adress {
    color: #005687;
    font-size: 16px;
    float: right;
    line-height: 45px;

    .custom-icon {
      margin-top: 13px;
      margin-right: 5px;
    }

    &:hover {
      text-decoration: underline;
      color: #005687;
    }
  }

  &-container {
    padding: 5px 0;
  }

  &-search {
    display: flex;
    flex: 1 0;
    margin: 0 25px 0 50px;
    position: relative;

    form {
      width: 100%;
    }

    input {
      width: 100%;
      height: 35px;
      padding-left: 10px;
      border: 1px solid #999999;
    }

    .btn-submit {
      width: 42px;
      height: 35px;
      position: absolute;
      right: 0;
      top: 0;
      background: #005687;

      .custom-icon {
        display: block;
        margin: 7px auto 0;
      }
    }
  }

  &-nav-links {
    .custom-icon {
      display: block;
      margin: 0 auto 5px;
    }

    a {
      display: inline-block;
      vertical-align: top;
      text-align: center;
      color: #666666;
      font-size: 13px;
      transition: all 0.5s;
      position: relative;
      margin-left: 25px;

      &:hover {
        color: #005687;
        text-decoration: underline;
      }
    }

    .count {
      width: 26px;
      height: 26px;
      display: block;
      position: absolute;
      right: 0;
      bottom: 10px;
      z-index: -1;
      border-radius: 50%;
      background: #ededed;
      line-height: 24px;
      color: #0076ba;
      font-size: 15px;
    }
  }

  &-favourites {
    .count {
      right: 5px;
    }
  }

  &-cart {
    .count {
      right: -5px;
    }
  }
}

.b-card-section {
  h1 {
    color: #005687;
    font-size: 25px;
    display: block;
  }

  .b-card-info {
    padding: 20px;
    margin-top: 15px;
    border: 1px solid #b8b8b8;

    .b-preview-list {
      ul {
        display: inline-block;
        vertical-align: top;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100px;

        li {
          margin-bottom: 20px;
          img {
            max-width: 100%;
          }

          &:hover {
            border: 1px solid #005687;
          }
        }
      }

      .preview-picture {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 120px);
        margin-left: 15px;

        img {
          max-width: 100%;
        }
      }
    }

    .item-left {
      display: inline-block;
      vertical-align: top;

      .price {
        color: #fd0000;
        font-size: 36px;
        font-family: $bold;
        display: block;
        line-height: 28px;

        span {
          font-size: 30px;
          font-family: $reg;
        }

        p {
          color: #005687;
          font-size: 14px;
          display: block;
          font-family: $reg;
          margin: 0;
        }
      }

      .counter {
        label {
          color: #626262;
          font-size: 14px;
          margin-right: 10px;
        }

        .min {
          height: 18px;
          width: 18px;
          border: 1px solid #b8b8b8;
          text-align: center;
          line-height: 14px;
          color: #005687;
          font-size: 14px;
          display: inline-block;
          vertical-align: top;
          margin-top: 5px;
        }

        .max {
          height: 18px;
          width: 18px;
          border: 1px solid #b8b8b8;
          text-align: center;
          line-height: 14px;
          color: #005687;
          font-size: 14px;
          display: inline-block;
          vertical-align: top;
          margin-top: 5px;
        }

        input {
          height: 18px;
          width: 18px;
          border: 1px solid #b8b8b8;
          display: inline-block;
          vertical-align: top;
          line-height: 14px;
          color: #005687;
          font-size: 14px;
          text-align: center;
          margin: 5px 4px 0 0;
        }
      }

      .select {
        label {
          color: #626262;
          font-size: 14px;
          margin-right: 10px;
        }

        select {
          height: 24px;
          width: 110px;
          border: 1px solid #b8b8b8;
          display: inline-block;
          vertical-align: middle;
          line-height: 14px;
          color: #005687;
          font-size: 14px;
          text-align: center;
          margin: 0 4px 0 0;
        }
      }

      .btn {
        max-width: 185px;
        height: 35px;
        display: block;
        margin: 10px 0 0;
        background: #005687;
        border-radius: 0;
        color: #ffffff;
        font-size: 18px;
        line-height: 30px;
        padding: 0;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);

        .custom-icon {
          margin-top: 8px;
          margin-right: 5px;
        }

        &:hover {
          background: #013b5c;
          box-shadow: none;
        }
      }
    }

    .item-right {
      float: right;
      text-align: center;

      .code {
        color: #626262;
        font-size: 14px;
        display: block;
        padding-bottom: 30px;
      }

      a {
        display: block;
        color: #666666;
        font-size: 13px;
        margin-bottom: 10px;

        .custom-icon {
          display: block;
          margin: 0 auto 10px;
        }

        &:hover {
          .bg-compare_icon_grey {
            background: url('../img/sprite/main-page.png') -469px -10px;
          }

          .bg-favourites_icon_grey {
            background: url('../img/sprite/main-page.png') -280px -406px;
          }
        }
      }
    }

    .list {
      margin-top: 15px;

      ul {
        padding: 0 0 0 17px;
        margin: 0;

        li {
          color: #005687;
          font-size: 14px;
          line-height: 17px;
          padding-top: 2px;

          span {
            color: #626262;
          }
        }
      }
    }

    .b-links {
      margin-top: 20px;
      a {
        color: #a6a6a6;
        font-size: 14px;
        display: inline-block;
        vertical-align: top;
        margin-right: 40px;
        text-align: center;

        .custom-icon {
          height: 30px;
          margin: 0 auto;
          display: block;
        }

        &:hover {
          text-decoration: underline;

          .bg-delivery_icon {
            background: url('../img/sprite/main-page.png') -147px -406px;
          }

          .bg-discount_card_icon {
            background: url('../img/sprite/main-page.png') -56px -406px;
          }

          .bg-sert_icon {
            background: url('../img/sprite/main-page.png') -152px -356px;
          }
        }
      }
    }

    .delivery-link {
      color: #a6a6a6;
      font-size: 14px;
      line-height: 15px;
      display: block;
      margin-top: 15px;

      &:hover {
        text-decoration: underline;

        .custom-icon {
          background: url('../img/sprite/main-page.png') -247px -356px;
        }
      }

      .custom-icon {
        float: left;
        margin: 8px 10px 0 0;
      }
    }
  }
}
.b-catalog-page {
  padding-bottom: 50px;

  .b-catalog-wrapper {
    display: flex;
    flex-wrap: wrap;

  }

  .b-catalog-container {
    width: calc(100% - 245px);
    margin-left: 20px;
  }

  .b-catalog-text {
    overflow: hidden;

    .text {
      overflow: hidden;
      height: 150px;

      &.show {
        height: auto;
      }
    }

    p {
      color: #4b4b4c;
      font-size: 16px;
      display: block;
      line-height: 20px;

      a {
        color: #005687;
        font-family: $bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .link {
      color: #005687;
      font-size: 16px;
      text-decoration: underline;
      float: right;

      &.hide {
        display: none;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .b-catalog-head {
    margin-top: 20px;

    h2 {
      color: #005687;
      font-size: 25px;
      display: block;
      padding-bottom: 35px;
    }

    .pagination {
      text-align: center;
      display: block;

      .prev {
        display: inline-block;
        vertical-align: top;
        color: #626262;
        font-size: 18px;
        font-family: $bold;

        &:hover {
          color: #005687;
        }
      }

      .next {
        display: inline-block;
        vertical-align: top;
        color: #626262;
        font-size: 18px;
        font-family: $bold;
        margin-left: 5px;

        &:hover {
          color: #005687;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
        display: inline-block;
        vertical-align: top;

        li {
          display: inline-block;
          vertical-align: top;
          margin: 0 2px;

          &.active {
            a {
              color: #005687;
              font-family: $bold;
            }
          }

          a {
            color: #626262;
            font-size: 18px;

            &:hover {
              color: #005687;
            }
          }
        }
      }
    }

    .tabs-links {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: right;

        li {
          display: inline-block;
          vertical-align: top;

          &.active {
            a {
              background: #005687;

              .bg-tabs_item_2 {
                background: url('../img/sprite/main-page.png') -315px -239px;
              }

              .bg-tabs_item_3 {
                background: url('../img/sprite/main-page.png') -315px -289px;
              }
            }
          }

          a {
            width: 30px;
            height: 30px;
            background: #ededed;
            display: block;
          }
        }
      }
    }
  }

  .tabs__content {
    display: none;

    &.active {
      display: block;
    }
  }

  .more-btn {
    max-width: 265px;
    display: block;
    margin: 0 auto 0;
    background: #005687;
    border-radius: 0;
    color: #ffffff;
    font-size: 18px;
    line-height: 30px;
    padding: 10px 0;
    text-align: center;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);

    &:hover {
      background: #013b5c;
    }

    span {
      padding: 0;
      margin: 0;
      font-size: 18px;
      line-height: 20px;
      display: block;
    }

    p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      display: block;
    }
  }

  .b-sort {
    label {
      color: #a6a6a6;
      font-size: 14px;
    }

    select {
      width: 140px;
      border: 1px solid #b8b8b8;
      height: 24px;
      line-height: 16px;
      color: #005687;
      font-size: 14px;
      padding-left: 10px;
      margin-left: 10px;
    }
  }

  .b-catalog-item {
    margin-bottom: 30px;
  }
}
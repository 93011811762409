@font-face {
  font-family: 'ClearSans-Regular';
  src: url('../fonts/clear-sans/EOT/ClearSans-Regular.eot');
  src: local('☺'), url('../fonts/clear-sans/WOFF/ClearSans-Regular.woff') format('woff'),
  url('../fonts/clear-sans/TTF/ClearSans-Regular.ttf') format('truetype'),
  url('../fonts/clear-sans/SVG/ClearSans-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ClearSans-Bold';
  src: url('../fonts/clear-sans/EOT/ClearSans-Bold.eot');
  src: local('☺'), url('../fonts/clear-sans/WOFF/ClearSans-Bold.woff') format('woff'),
  url('../fonts/clear-sans/TTF/ClearSans-Bold.ttf') format('truetype'),
  url('../fonts/clear-sans/SVG/ClearSans-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$reg: 'ClearSans-Regular';
$bold: 'ClearSans-Bold';

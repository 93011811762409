.b-footer {
  background: #005687;
  padding: 55px 0 50px;

  h4 {
    color: #ffffff;
    font-size: 18px;
    font-family: $bold;
    display: block;
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-top: 5px;

      a {
        color: #ffffff;
        font-size: 18px;
        line-height: 20px;

        &:hover {
          text-decoration: underline;
          color: #b7b7b7;
        }
      }
    }
  }

  &-adress {
    padding-top: 5px;
    color: #ffffff;
    font-size: 15px;
    display: block;
    line-height: 20px;

    .custom-icon {
      margin-right: 10px;
      margin-top: 4px;
    }
  }

  &-phone {
    padding-top: 5px;

    p {
      color: #ffffff;
      font-size: 18px;
      display: block;
      padding: 0 0 0 25px;
      margin: 0;
      line-height: 22px;
    }

    .custom-icon {
      margin-right: 10px;
      margin-top: 5px;
      float: left;
    }
  }

  &-email {
    padding-top: 5px;

    a {
      color: #ffffff;
      font-size: 16px;
      display: block;
      padding: 0 0 0 25px;
      margin: 0;
      line-height: 22px;

      p {
        border-bottom: 1px dotted;
        display: inline;
        padding: 0;
        margin: 0;
      }

      &:hover {
        color: #b7b7b7;
      }
    }

    .custom-icon {
      margin-right: 10px;
      margin-top: 7px;
      float: left;
    }
  }
}

.copyright {
  color: #616161;
  font-size: 14px;
  text-align: center;
  background: #ededed;
  padding: 5px 0;
}

.footer-seo-text {
  padding-top: 15px;
  padding-bottom: 5px;

  p {
    color: #4b4b4c;
    font-size: 16px;
    display: block;
    line-height: 20px;
  }
}
.range-slider {
  margin-bottom: 20px;

  .input-with-keypress-min {
    width: 50px;
    height: 25px;
    border: 1px solid #b8b8b8;
    text-align: center;
    color: #626262;
    font-size: 14px;
  }

  .line {
    content: '';
    width: 10px;
    height: 1px;
    background: #b8b8b8;
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
  }

  .input-with-keypress-max {
    width: 65px;
    height: 25px;
    border: 1px solid #b8b8b8;
    text-align: center;
    color: #626262;
    font-size: 14px;
  }

  .text {
    color: #a6a6a6;
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
  }

  .range-slider {
    margin-top: 25px;
  }

  .noUi-connect {
    background: #588ba9;
  }

  .noUi-target {
    height: 4px;
    background: #dbdbdb;
    border-radius: 2px;
    border: none;
    box-shadow: none;
  }

  .noUi-handle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    box-shadow: none;
    border: none;
    padding: 1px;
    border: 1px solid #a6a6a6;
    right: -5px !important;
    top: -5px;
    cursor: pointer;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-shadow: none;
      border: none;
      background: #a6a6a6;
      left: 1px;
      top: 1px;
    }

    &:after {
      display: none;
    }
  }

  .item-left {
    display: inline-block;
    vertical-align: top;

    .text {
      margin: 0 0 0 5px;
    }
  }

  .item-right {
    float: right;

    .text {
      margin: 0 0 0 2px;
    }
  }

  .skip-value-lower {
    color: #626262;
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
  }

  .skip-value-upper {
    color: #626262;
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
  }
}
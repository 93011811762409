.b-sub-catalog-list {
  padding-bottom: 40px;

  .item {
    height: 100%;
    border: 1px solid #b8b8b8;
    padding-bottom: 50px;
    position: relative;
    margin-bottom: 20px;

    .img {
      width: 100%;
      text-align: center;
      margin-top: 25px;

      img {
        max-width: 100%;
        display: inline-block;
      }
    }

    .link {
      color: #005687;
      font-size: 18px;
      font-family: $bold;
      display: block;
      text-decoration: none;
      text-align: center;
      margin-top: 10px;
      padding: 0 15px;
      line-height: 20px;
      margin-bottom: 10px;

      &:hover {
        text-decoration: underline;
      }
    }

    .list {
      padding: 5px 18px 0;
      margin: 0;
      list-style: none;

      li {
        line-height: 18px;
        padding-bottom: 5px;

        &:before {
          content: '';
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: #005687;
          display: inline-block;
          vertical-align: top;
          margin: 12px 5px 0 0;
        }
        a {
          color: #626262;
          font-size: 14px;
          line-height: 18px;
          display: inline;

          &:hover {
            text-decoration: underline;
            color: #005687;
          }
        }
      }
    }

    .link-more {
      color: #005687;
      font-size: 18px;
      position: absolute;
      right: 20px;
      bottom: 20px;

      &:hover {
        color: #013b5c;
        text-decoration: underline;
      }

      .custom-icon {
        margin: 11px 0 0 5px;
      }
    }
  }
}
.b-main-menu {
  min-height: 40px;
  background: #005687;

  .b-burger-menu {
    float: left;
    margin-top: 10px;
    display: none;
  }

  .b-first-menu {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;

    .custom-icon {
      margin-top: 10px;
      margin-right: 10px;
      margin-left: 10px;
    }

    & > li {
      &:first-child {
        a {
          padding-left: 0;
        }
      }

      a {
        height: 40px;
        padding: 0 20px;
        line-height: 37px;
        color: #ffffff;
        font-size: 18px;
        display: block;
      }

      &:hover {
        & > a {
          background: #ededed;
          color: #005687;

          .custom-icon {
            background: url('../img/sprite/main-page.png') -54px -454px;
          }
        }
        .b-sub-menu {
          display: block;
        }

        & > a {
          background: #ededed;
          color: #005687;
        }
      }
    }

    .b-sub-menu {
      width: 100%;
      position: absolute;
      z-index: 5;
      left: 0;
      top: 40px;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
      display: none;
      background: #fff;

      .b-second-menu {
        height: 100%;
        background: #ededed;
        padding: 15px 0;
        margin: 0;
        list-style: none;

        & > li {
          margin-top: -2px;

          &:last-child {
            a {
              border: none;
            }
          }

          &:hover {
            background: #fff;

            .b-third-menu-container {
              display: block;
            }
          }

          & > a {
            color: #000000;
            font-size: 18px;
            border-bottom: 2px solid #fff;
            padding: 2px 0 0 0;
            margin-left: 30px;
            position: relative;
            z-index: 5;

            &:hover {
              background: #fff;
            }
          }
        }
      }
    }

    .b-third-menu-container {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: none;

      ul {
        list-style: none;
        margin: 0;
        padding: 10px 15px;

        & > li {
          margin-top: -2px;
          padding: 0 15px;
          display: block;

          &:hover {
            background: none;
          }

          &:last-child {
            a {
              &:hover {
                border-bottom: 2px solid #005687;
              }
            }
          }

          a {
            height: auto;
            width: 100%;
            line-height: 22px;
            color: #4b4b4c;
            display: block;
            padding: 12px 0 10px 0;
            border-bottom: 2px solid #ededed;
            background: none;

            &:hover {
              color: #005687;
              border-bottom: 2px solid #005687;
              background: none;
            }
          }
        }
      }
    }
  }
}
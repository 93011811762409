.nice-select {
  height: 20px;
  border-radius: 0;
  float: none;
  display: inline-block;
  vertical-align: top;
  padding: 0 20px 0 5px;
  line-height: 18px;
  margin-top: 5px;

  span {
    color: #005687;
    font-size: 14px;
  }

  &:after {
    margin-top: -3px;
    right: 9px;
    color: #005687;
    border-bottom: 2px solid #005687;
    border-right: 2px solid #005687;
  }
}
.b-article {
  padding: 30px 0;
  background: url("../img/general/bg-article.png") no-repeat bottom right / cover;
}

.b-seo-article {
  h2 {
    color: #005687;
    font-size: 25px;
    margin-bottom: 25px;

    .custom-icon {
      margin-right: 10px;
    }
  }

  p {
    color: #000000;
    font-size: 18px;
    display: block;
    line-height: 22px;

    a {
      color: #005687;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.b-new-list {
  h2 {
    color: #005687;
    font-size: 25px;
    margin-bottom: 25px;
    display: inline-block;

    .custom-icon {
      margin-right: 10px;
    }
  }

  .link {
    color: #005687;
    font-size: 18px;
    display: block;
    line-height: 20px;
    text-decoration: underline;
    margin-bottom: 10px;
    margin-top: 20px;

    &:hover {
      color: #013b5c;
    }
  }

  p {
    color: #000000;
    font-size: 16px;
    line-height: 20px;
  }

  .read-more {
    color: #005687;
    font-size: 16px;
    float: right;
    line-height: 20px;
    text-decoration: underline;
    margin-bottom: 10px;

    &:hover {
      color: #013b5c;
    }
  }
}
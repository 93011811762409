.b-similar-items {
  margin-top: 30px;

  .tabs__caption {
    padding: 0;
    margin: 0;
    list-style: none;
    border-bottom: 1px solid #005687;

    li {
      display: inline-block;
      vertical-align: top;
      margin-left: 20px;

      &.active {
        a {
          font-family: $bold;
          background: #005687;
          color: #fff;
        }
      }

      a {
        color: #000000;
        font-size: 18px;
        display: block;
        background: #ededed;
        padding: 10px 20px;
      }
    }
  }

  .item {
    .b-catalog-list {
      margin: 30px 70px 0;
    }

    .catalog-slider-item {
      margin: 0 35px;
    }

    .slick-prev {
      width: 24px;
      height: 42px;
      position: absolute;
      left: -50px;
      top: calc(50% - 21px);
      font-size: 0;
      border: none;
      cursor: pointer;
      z-index: 5;
      background: url('../img/sprite/main-page.png') -374px -278px;

      &:hover {
        background: url('../img/sprite/main-page.png') -418px -278px;
        transform: rotate(180deg);
      }
    }

    .slick-next {
      width: 24px;
      height: 42px;
      position: absolute;
      right: -50px;
      top: calc(50% - 21px);
      font-size: 0;
      border: none;
      cursor: pointer;
      z-index: 5;
      background: url('../img/sprite/main-page.png') -374px -278px;
      transform: rotate(180deg);

      &:hover {
        background: url('../img/sprite/main-page.png') -418px -278px;
        transform: rotate(0);
      }
    }
  }
}